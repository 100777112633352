import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.store', route: { name: 'store' } },
      ],
      listRoute: () => ({ name: 'store' }),
      afterCreateRoute: result => ({
        name: 'store-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.store',
      defaultData: this._defaultData,
      blocks: [
        {
          title: 'form_block.base',
          data: {
            name: {
              required: true,
              label: 'data.name',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            uniform_number: {
              label: 'data.uniform_number',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            member_name: {
              label: 'data.manager-name',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            phone: {
              label: 'data.phone',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            address: {
              label: 'data.deliver-address',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },

        {
          title: 'store-member.form_block',
          data: {
            id: {
              component: () => import('modules/member/views/storeMemberList.vue'),
              grid: {xl: 12, lg: 12, md: 12},
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
    }
  }
}

export default new formConfig()

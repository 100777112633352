import { render, staticRenderFns } from "./storeForm.vue?vue&type=template&id=22d19166&scoped=true&"
import script from "./storeForm.vue?vue&type=script&lang=babel&"
export * from "./storeForm.vue?vue&type=script&lang=babel&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22d19166",
  null
  
)

export default component.exports